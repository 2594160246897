import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import Button from "@material-ui/core/Button";
import axios from "axios";
import moment from "moment";
import { isMobile } from 'react-device-detect';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';

function StatusBar(props) {

  const [total, setTotal] = React.useState(0);
  const [totalMeses, setTotalMeses] = React.useState(0);
  const [totalDias, setTotalDias] = React.useState(0);
  const [order, setOrder] = useState(0);
  const [loading, setLoading] = React.useState(true);

  const svgRef = useRef();
  const btnRef = useRef();

  //primer gráfico
  const chart = (data, total) => {

    const margin = ({ top: 20, right: 0, bottom: 30, left: 40 });
    const height = 400;
    let width = 500;

    if (isMobile) {
      width = 350;
    }

    const x = d3.scaleBand()
      .domain(data.map(d => d.name))
      .range([margin.left, width - margin.right])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)]).nice()
      .range([height - margin.bottom, margin.top]);

    const xAxis = g => g
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(0));

    const yAxis = g => g
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(y))
      .call(g => g.select(".domain").remove());


    const svg = d3.select(svgRef.current);

    //responsive
    svg
      .attr("height", height)
      .attr("width", width)
      .attr("viewBox", `0 0 ${height} ${width}`)

    //Create Title
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", 12)
      .style("text-anchor", "middle")
      .style("font-size", "16px")
      .style("text-decoration", "none")
      .style("font-weight", "bold")
      .text("");


    const bar = svg.append("g")
      .attr("fill", props.color)
      .selectAll("rect")
      .data(data)
      .join("rect")
      .style("mix-blend-mode", "multiply")
      .attr("x", d => x(d.name))
      .attr("y", d => y(d.value))
      .attr("height", d => y(0) - y(d.value))
      .attr("width", x.bandwidth())
      .on("click", function () {
        sortBars();
      })
      .on("mouseover", function () {
        d3.select(this).style("fill", "#024ebc");
      })
      .on("mouseout", function () {
        d3.select(this).style("fill", props.color);
      });

    //texto sobre las barras

    const gx = svg.append("g")
      .call(xAxis);

    const gy = svg.append("g")
      .call(yAxis);


    //Define sort order flag
    var sortOrder = false;

    const btn = d3.select(btnRef.current);
    btn.on("click", function () {
      sortBars();
    });

    //Define sort function
    var sortBars = function () {
      //Flip value of sortOrder
      sortOrder = !sortOrder;
      if (sortOrder) {
        x.domain(data.sort((a, b) => a.value - b.value).map(d => d.name));
        const t = svg.transition()
          .duration(750);

        bar.data(data, d => d.name)
          .order()
          .transition(t)
          .delay((d, i) => i * 20)
          .attr("x", d => x(d.name));

        gx.transition(t)
          .call(xAxis)
          .selectAll(".tick")
          .delay((d, i) => i * 20);
      } else {
        x.domain(data.sort((a, b) => b.value - a.value).map(d => d.name));
        const t = svg.transition()
          .duration(750);

        bar.data(data, d => d.name)
          .order()
          .transition(t)
          .delay((d, i) => i * 20)
          .attr("x", d => x(d.name));

        gx.transition(t)
          .call(xAxis)
          .selectAll(".tick")
          .delay((d, i) => i * 20);
      }

    };

  };
  //fin primer grafico





  useEffect(() => {

    let token = sessionStorage.getItem("Token");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_URL}api/firmantes`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then(function (response) {

        let arr = response.data;
        var date = new Date();

        var firstDay, lastDay;

        var mes = [];

        for (var i = 6; i >= 0; i--) {
          var cont = 0, sum = 0;
          var monthname = "";
          firstDay = new Date(date.getFullYear(), date.getMonth() - i, 1);
          lastDay = new Date(date.getFullYear(), (date.getMonth() - i) + 1, 0);
          monthname = moment(firstDay).format("MMM");
          var resultMonth = arr.filter(function (a) {
            return (
              new Date(a.created_at) >= firstDay &&
              new Date(a.created_at) <= lastDay
            );
          });

          resultMonth.forEach((item, i) => {
            if (item.date_signed) {

              let dateOneObj = "";
              if (item.date_notified) {
                dateOneObj = new Date(item.date_notified);
              } else {
                dateOneObj = new Date(item.created_at);
              }
              //const dateOneObj = new Date(item.date_notified);
              const dateTwoObj = new Date(item.date_signed);
              const milliseconds = Math.abs(dateTwoObj - dateOneObj);
              const hours = milliseconds / 36e5;
              const minutes = Math.floor(milliseconds / 1000 / 60);
              sum += hours;
              cont++;
            }
          });
          var prom = (sum / cont) ? (sum / cont).toFixed(1) : "";
          mes = [...mes, { name: monthname, value: prom }]
        } //endfor
        console.log("mes->", mes);
        chart(mes, 0);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });


  }, [order]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography color="dark" gutterBottom>
            <font size="3">
              <b>HORAS EN PROMEDIO DE LOS FIRMANTES</b>
            </font>
          </Typography>
        </Box>
      </Grid>
      <hr />
      {
        loading ? (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <CircularProgress />
          </div>
        ) : null
      }
      <Grid item xs={12}>

        <Box display="flex" justifyContent="center" alignItems="center">
          <svg
            ref={svgRef}
          >
          </svg>
          <Button
            ref={btnRef}
            variant="outlined"
            color="primary"
          >
            Ordenar
          </Button>
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export default StatusBar;
