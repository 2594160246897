import React, { useLayoutEffect, useState } from "react";
import { render } from "react-dom";
import { isMobile } from 'react-device-detect';
import './style.css'; // css styling from the react-vis node module
import { RadialChart } from "react-vis";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

export default function GraficaPie(props) {
/*
  setDataPie([
    { angle: s1, color: "#FFF", name: "blue", label:s1 },
    { angle: s2, color: "#000", name: "yellow", label:s2 },
    { angle: s3, color: "#1E96BE", name: "cyan", label:s3 },
    { angle: s4, color: "#DA70BF", name: "magenta", label:s4 },
    { angle: s5, color: "#F6D18A", name: "yellow again", label:s5 }
  ])
*/
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      if(isMobile){
        setSize([300, 300]);
      }
      else{
        if(props.modal){
          setSize([500, 300]);
        }
        else{
          setSize([window.innerWidth-300, 300]);
        }
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

  const [width, height] = useWindowSize();

  //console.log(props.data)

    return (
    <React.Fragment>
    <hr />
    <Grid item xs={12}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography color="dark" gutterBottom>
          <font size="5">
            <b>TOTALES POR ESTATUS</b>
          </font>
        </Typography>
      </Box>
      <RadialChart
        showLabels={false}
        data={props.data}
        width={width}
        height={height}
        colorType="literal"
        showLabels
        labelsStyle={{
            fontSize: 12,
            fontWeight: "bold",
            letterSpacing: 0.4
          }}
      />
      <Box display="flex" justifyContent="center" alignItems="center">
      {(props.data)?(
        props.data.map((item) => (
          <React.Fragment>
          <span>&nbsp;<lable Style={"background-color:"+item.color+"; color:white"}><font size="1">{item.name} ({item.angle})</font></lable></span>
          </React.Fragment>
        ))
      ) :null}
      </Box>
    </Grid>
    <hr />

    </React.Fragment>
  );
};
